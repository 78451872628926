import React from 'react';
import { graphql, navigate } from 'gatsby';
import Image, { FixedObject } from 'gatsby-image';
import SEO from '../../../components/SEO';
import Layout from '../../../components/Layout/Layout';
import Section from '../../../components/Section/Section';
import ShapeTitle from '../../../components/ShapeTitle/ShapeTitle';
import CSROnly from '../../../components/CSROnly/CSROnly';
import Mailto from '../../../components/Mailto';
import * as price from '../../../modules/product/price';
import * as selector from '../../../modules/cart/selector';
import Cart from '../../../modules/cart/Cart';
import HeartTop from '../../../images/artworks/cart/success/heart-top.inline.svg';
import HeartBottom from '../../../images/artworks/cart/success/heart-bottom.inline.svg';

export const query = graphql`
  query {
    image: file(relativePath: { eq: "photos/cart/success/1.jpg" }) {
      childImageSharp {
        fixed(width: 99, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

type Props = {
  data: {
    image: {
      childImageSharp: {
        fixed: FixedObject;
      };
    };
  };
};

const CartSuccessPage: React.FC<Props> = ({ data }) => {
  const [storage, dispatch] = Cart.useCart();
  const [inMemory] = React.useState(storage);
  const items = selector.getCartItems(inMemory);
  const total = selector.getTotalItems(inMemory);
  const shipping = selector.getShippingItems(inMemory);
  const amountCoupon = selector.getAmountCoupon(inMemory, total);
  const totalWithCoupon = selector.getTotalCoupon(inMemory, total);
  const totalWithShipping = totalWithCoupon + shipping;

  React.useEffect(() => {
    dispatch({ type: 'RESET' });
  }, []);

  if (typeof window !== 'undefined' && !items.length) {
    // Don't render the page client side with an empty cart, redirect.
    navigate('/shop/', { replace: true });
    return null;
  }

  return (
    <Layout
      title={
        <span className="relative block fsz-xxlg lh-xxlg cherry">
          <HeartTop
            className="absolute -translateX-1/2"
            style={{ top: '-28px', left: 'calc(50% - 35px)' }}
          />
          Merci <br />
          <span className="pink">pour votre confiance</span>
          <HeartBottom
            className="absolute -translateX-1/2"
            style={{ bottom: '-40px', right: 'calc(50% - 115px)' }}
          />
        </span>
      }
      crumbs={[
        { name: 'Accueil', path: '/' },
        { name: 'Shop', path: '/shop/' },
        { name: 'Confirmation', path: '/panier/confirmation/' },
      ]}
    >
      <SEO
        title="Commande confirmée | Poupée Odette"
        robots="noindex, nofollow"
      />

      <Section className="-mrt-40x -mrb-25x">
        <div className="ta-center mrb-40x md:mrb-10x">
          <Image className="brr-1/2" fixed={data.image.childImageSharp.fixed} />
        </div>
        <div className="flex flex-wrap flex-column justify-around mw-980x mrr-auto mrl-auto -mrb-25x sm:flex-row">
          <div className="mw-225x mrb-35x mrl-20x sm:mrb-0 sm:mrl-0">
            <ShapeTitle name="cartDelay">1 à 2 semaines</ShapeTitle>
            <p className="fsz-md pdl-10x">
              de fabrication pour votre poupée dès réception de la commande.
            </p>
          </div>

          <div className="mw-245x mrb-40x mrr-10x self-end sm:mrr-0 sm:self-auto sm:pdt-40x">
            <ShapeTitle name="cartEmail">Un email !</ShapeTitle>
            <p className="fsz-md pdl-10x">
              Nous vous contactons dès que votre poupée est en route avec son
              numéro de suivi.
            </p>
          </div>

          <div className="mw-255x mrb-20x mrl-20x sm:mrl-0 sm:pdt-10x">
            <ShapeTitle name="cartUrgent">C’est urgent ?</ShapeTitle>
            <p className="fsz-md pdl-10x">
              Écrivez-nous à{' '}
              <Mailto className="current" address="hello@poupeeodette.fr">
                hello@poupeeodette.fr
              </Mailto>{' '}
              et nous ferons notre max pour vous la livrer à temps pour
              l’événement.
            </p>
          </div>
        </div>
      </Section>

      <CSROnly fallback={<div className="h-366x" />}>
        <Section>
          <h2 className="ff-lora blue fsz-lg fw-regular ta-center mrb-10x">
            Votre commande est confirmée
          </h2>
          <p className="fsz-md fw-bold ta-center mrb-25x">
            Un email récapitulatif vous a été envoyé
          </p>
          <ul className="mw-680x mrr-auto mrl-auto pdr-10x pdl-10x">
            {items.map((item) => (
              <li
                key={item.product.variant.id}
                className="mrb-15x brw-1x brs-s brc-e7e7e7 brr-4x bs-1x-4x pdt-15x pdr-15x pdb-15x pdl-15x"
              >
                <div className="flex">
                  <Image
                    className="flex-075 mw-165x mrr-20x"
                    fluid={item.product.variant.images[0].childImageSharp.fluid}
                  />
                  <div className="flex flex-1 flex-column justify-between">
                    <div>
                      <p className="flex w-full justify-between items-center ff-lora fsz-lg fsy-italic blue mrb-5x">
                        <span>{item.product.name}</span>
                      </p>
                      {item.product.variant.name && (
                        <p className="fsz-md mrb-5x">
                          {item.product.variant.name}
                        </p>
                      )}
                      <p className="fsz-md mrb-5x fw-bold">
                        Prix : {price.format(item.product.variant.price)} €
                      </p>
                      <p className="fsz-md mrb-5x fw-bold">
                        Qté : {item.quantity}
                      </p>
                    </div>
                    <div className="fsz-md fw-bold tt-upper">
                      Sous-total : {price.format(selector.getTotalItem(item))} €
                    </div>
                  </div>
                </div>
              </li>
            ))}
            <li className="mrb-15x brw-1x brs-s brc-e7e7e7 brr-4x bs-1x-4x pdt-15x pdr-15x pdb-15x pdl-15x">
              <p className="ff-lora fsz-lg fsy-italic tt-upper blue mrb-25x">
                Total commande
              </p>
              <div>
                <p className="flex mrb-15x justify-between tt-upper fsz-md">
                  <span className="fw-bold">Sous-total</span>
                  <span>{price.format(total)} €</span>
                </p>
                <p className="flex mrb-15x justify-between tt-upper fsz-md">
                  <span className="fw-bold">Livraison colissimo</span>
                  <span>{price.format(shipping)} €</span>
                </p>
                {inMemory.coupon && (
                  <p className="flex mrb-15x justify-between tt-upper fsz-md">
                    <span className="fw-bold">{inMemory.coupon.id}</span>
                    <span>-{price.format(amountCoupon)} €</span>
                  </p>
                )}
                <p className="flex mrb-15x justify-between tt-upper fsz-md fw-bold orange">
                  <span className="fw-bold">Total</span>
                  <span>{price.format(totalWithShipping)} €</span>
                </p>
              </div>
            </li>
          </ul>
        </Section>
      </CSROnly>
    </Layout>
  );
};

export default CartSuccessPage;

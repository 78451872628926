import React from 'react';

type Props = {
  fallback?: React.ReactNode;
};

const CSROnly: React.FC<Props> = ({ fallback, children }) => {
  const [state, setState] = React.useState(false);

  React.useEffect(() => {
    setState(true);
  }, []);

  if (!state) {
    return <React.Fragment>{fallback}</React.Fragment>;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default CSROnly;
